<template>
    <div class="mx-auto w-full max-w-sm">
        <div>
            <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                {{ $translate('auth.registration.title') }}
            </h2>
            <p class="mt-2 text-sm leading-5 text-gray-600 max-w">
                {{ $translate('auth.registration.or') }}
                <router-link :to="{name: 'login'}" tag="a"
                             class="font-medium text-custom-600 hover:text-custom-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    {{ $translate('auth.registration.signin') }}
                </router-link>
            </p>
        </div>
        <div class="mt-8">
            <div class="mt-6 space-y-6">
                <DataForm :form="form" v-model="values"/>
                <span class="block w-full rounded-md shadow-sm">
                    <button type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500"
                            @click="register">
                            <span class="pr-2" v-if="values.loading"><i class="fal fa-spinner-third fa-spin"></i></span>
                        {{ values.loading ? $translate('auth.registration.creating') : $translate('auth.registration.create') }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, reactive} from 'vue';
    import {BaseForm, FormGrid} from "@/types/form";
    import {
        EmailValidationRule, FormValidation,
        MinLengthValidationRule,
        RequiredValidationRule
    } from "@/types/validation";
    import {PasswordField, TextField} from "@/types/field";
    import {userStore} from "@/store/user";
    import router from "@/router";
    import {useRouterHelper} from "@/plugins/routerHelper";

    export default defineComponent({
        name: "Registration",
        setup() {

          const routerHelper = useRouterHelper();

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));
            form.addField(
                new TextField('name', 'auth.registration.name')
                    .setAutocomplete('name')
                    .addRule(new RequiredValidationRule())
                    .addRule(new MinLengthValidationRule(2))
            );
            form.addField(
                new TextField('email', 'auth.registration.email')
                    .setAutocomplete('username')
                    .addRule(new RequiredValidationRule())
                    .addRule(new EmailValidationRule())
            );
            form.addField(
                new PasswordField('password', 'auth.registration.password')
                    .addRule(new RequiredValidationRule())
                    .addRule(new MinLengthValidationRule(8))
                    .setAutocomplete('current-password')
            );

            //  Data object with account details
            const values = reactive({
                loading: false,
                name: '',
                email: '',
                password: ''
            });

            //  Register method
            const register = () => {
                form.submit().then(() => {
                    values.loading = true;
                    const referral = routerHelper.getQueryParameter('ref');
                    userStore.register(values.name, values.email, values.password, referral ?? '')
                        .then(() => {
                            values.loading = false;
                            router.push({name: 'dashboard'})
                        })
                        .catch(error => {
                            values.loading = false;
                            form.error = error;
                        });
                });
            }

            return {
                register,
                form,
                values
            }
        }
    })
</script>