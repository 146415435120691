
    import {defineComponent, reactive} from 'vue';
    import {BaseForm, FormGrid} from "@/types/form";
    import {
        EmailValidationRule, FormValidation,
        MinLengthValidationRule,
        RequiredValidationRule
    } from "@/types/validation";
    import {PasswordField, TextField} from "@/types/field";
    import {userStore} from "@/store/user";
    import router from "@/router";
    import {useRouterHelper} from "@/plugins/routerHelper";

    export default defineComponent({
        name: "Registration",
        setup() {

          const routerHelper = useRouterHelper();

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));
            form.addField(
                new TextField('name', 'auth.registration.name')
                    .setAutocomplete('name')
                    .addRule(new RequiredValidationRule())
                    .addRule(new MinLengthValidationRule(2))
            );
            form.addField(
                new TextField('email', 'auth.registration.email')
                    .setAutocomplete('username')
                    .addRule(new RequiredValidationRule())
                    .addRule(new EmailValidationRule())
            );
            form.addField(
                new PasswordField('password', 'auth.registration.password')
                    .addRule(new RequiredValidationRule())
                    .addRule(new MinLengthValidationRule(8))
                    .setAutocomplete('current-password')
            );

            //  Data object with account details
            const values = reactive({
                loading: false,
                name: '',
                email: '',
                password: ''
            });

            //  Register method
            const register = () => {
                form.submit().then(() => {
                    values.loading = true;
                    const referral = routerHelper.getQueryParameter('ref');
                    userStore.register(values.name, values.email, values.password, referral ?? '')
                        .then(() => {
                            values.loading = false;
                            router.push({name: 'dashboard'})
                        })
                        .catch(error => {
                            values.loading = false;
                            form.error = error;
                        });
                });
            }

            return {
                register,
                form,
                values
            }
        }
    })
